
export const optionalQuestions = [
  {
    question: "What is the capital city of France?",
  type: "optional",
  option1: "Berlin",
  option2: "Madrid",
  option3: "Paris",
  option4: "Rome"
  },
  {
    question: "Who wrote the play Romeo and Juliet?",
    type: "optional",
    option1: "Charles Dickens",
    option2: "Mark Twain",
    option3: "William Shakespeare",
    option4: "Leo Tolstoy",
  },
  {
    question: "What is the largest planet in our solar system?",
    type: "optional",
    option1: "Venus",
    option2: "Mars",
    option3: "Jupiter",
    option4: "Saturn",
  },
  {
    question: "Which of the following is NOT a function of HRM?",
    type: "optional",
    option1: "Recruitment",
    option2: "Training",
    option3: "Product Development",
    option4: "Performance Management",
  },
  {
    question: "Which element is necessary for the process of photosynthesis?",
    type: "optional",
    option1: "Carbon Dioxide",
    option2: "Nitrogen",
    option3: "Helium",
    option4: "Hydrogen",
  },
  {
    question: "Which element is represented by the symbol 'O' on the periodic table?",
    type: "optional",
    option1: "Oxygen",
    option2: "Silver",
    option3: "Gold",
    option4: "Carbon",
  },
  {
    question: "What is the purpose of training?",
    type: "optional",
    option1: "To determine salary scales",
    option2: "To identify gaps in employee skills and knowledge",
    option3: "To evaluate employee satisfaction",
    option4: "To assess company profits",
  },
  {
    question: "Where does the term 'computer' originate from?",
    type: "optional",
    option1: "Latin word 'Computare'",
    option2: "Greek word 'Computus'",
    option3: "English word 'Compute'",
    option4: "French word 'Compter'",
  },
  {
    question: "What does 'Computare' mean?",
    type: "optional",
    option1: "To compute",
    option2: "To calculate",
    option3: "To process",
    option4: "To analyze",
  },
  {
    question: "What is a computer?",
    type: "optional",
    option1: "A mechanical device",
    option2: "A programmable electronic device",
    option3: "A communication device",
    option4: "A storage device",
  },
];


export const longQuestions = [
  {
    question: "What is an operating system?",
    type: "short"
  },
  {
    question: "Explain the difference between RAM and ROM?",
    type: "short"
  },
  {
    question: "What is a database?",
    type: "short"
  },
  {
    question: "Define a computer and explain its primary function.",
    type: "desc"
  },
  {
    question: "What is the difference between developed and developing countries?",
    type: "desc"
  },
  {
    question: "What are the primary functions of human resource management?",
    type: "desc"
  },
]