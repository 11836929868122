import { NavigationCards } from "./navigation-cards";
import { DashboardLayout } from "./DashboardLayout";

import { Exams } from "src/components/ui/exams";
import { DashboardHeader } from "./dashboard-header";
import { Notifications } from "./notifications";
import { FAQCard } from "src/components/ui/faq-card";
import GuidelineBubble from "src/components/ui/guideline-bubble";
import { Link } from "react-router-dom";

export const DashboardView = () => {
  return (
    <DashboardLayout>
      <main className="flex flex-grow flex-col gap-8 px-2 py-3">
        <DashboardHeader />
        <section className="flex h-full w-full flex-col items-center justify-center gap-8 md:gap-20">
          <div className="flex flex-col gap-20 justify-self-center md:flex-row">
            <NavigationCards />
          </div>
          <div className="flex flex-wrap gap-1 sm:gap-20 justify-center items-center">
            <Link to="/tutorial">
            <div className="rounded-md hover:shadow-slate-400 hover:shadow-lg hover:bg-violet-400 bg-primary text-white w-52 h-12 text-lg font-semibold flex justify-center items-center gap-3">Video Tutorial <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-video"><path d="m16 13 5.223 3.482a.5.5 0 0 0 .777-.416V7.87a.5.5 0 0 0-.752-.432L16 10.5"/><rect x="2" y="6" width="14" height="12" rx="2"/></svg></div>
            </Link>
            <Link to="/mocktest">
            <div className="rounded-md hover:shadow-slate-400 hover:shadow-lg hover:bg-violet-400 bg-primary text-white w-52 h-12 text-lg font-semibold flex justify-center items-center gap-3">Mock Test <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64 464c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0z" fill="currentColor"/></svg></div>
            </Link>
          </div>
        </section>
        <Notifications />
        <div>
          <div className="mb-10 flex justify-center">
            <h1 className="text-xl font-bold my-3 w-[70%] text-center text-red-500">To avoid missing the exam, You must click the "Start Exam" button 10 minutes earlier of the time showing here</h1>
          </div>
          <div className="mx-auto overflow-auto rounded-lg border p-3 shadow-lg min-h-[250px] lg:min-h-[210px] lg:w-[600px] xl:w-[800px]">
            <Exams />
          </div>
        </div>
        <FAQCard className="pt-16 lg:hidden" />
      </main>
      <FAQCard className="absolute bottom-3 left-10 hidden w-[330px] lg:block" />
      <GuidelineBubble />
    </DashboardLayout>
  );
};
