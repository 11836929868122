import VideoComponent from "src/components/ui/VideoComponent"
import { DashboardLayout } from "../Dashboard/DashboardLayout"


const TutorialView = () => {

  const urlEnglish = "https://drive.google.com/file/d/1UoAHWpNa5-9nzG_aEzf-lHBca8V-O6LJ/view?usp=sharing";
  const urlHindi = "https://drive.google.com/file/d/1wdignhbzKjgf7Gwubu3dlaKQerGhaIM2/view?usp=sharing";

  function getEmbedLink(driveLink: string) {

  const fileIdMatch = driveLink.match(/(?:\/d\/|id=|\/uc\?id=)([a-zA-Z0-9_-]+)/);
  
  if (fileIdMatch && fileIdMatch[1]) {
    const fileId = fileIdMatch[1];

    return `https://drive.google.com/file/d/${fileId}/preview`;
  } else {
      console.error("Invalid Google Drive link.");
      return null;
    }
  }

  const ENGLISH = getEmbedLink(urlEnglish);
  const HINDI = getEmbedLink(urlHindi);

  return (
    <DashboardLayout>
      <div className="w-full overflow-auto h-full flex flex-col justify-center xl:block">
        <div className="mx-auto my-6 flex flex-col justify-center items-center">
          <h1 className="text-lg xl:text-xl my-6 text-center font-semibold">Comprehensive Video Guide: How to Successfully Take the Exam (English)</h1>
          <VideoComponent src = {ENGLISH!}/> 
        </div>
        
         <div className=" mx-auto my-6 flex flex-col justify-center items-center">
          <h1 className="text-lg xl:text-xl my-6 text-center font-semibold">Comprehensive Video Guide: How to Successfully Take the Exam (Hindi)</h1>
          <VideoComponent src = {HINDI!}/> 
         </div>
        
      </div>
    </DashboardLayout>
  )
}

export default TutorialView