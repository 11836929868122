
import { convertDateToIST } from "src/lib/utils";
import { useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/ui/table";
import { Button } from "src/components/ui/button";

export const MockExams = () => {
  const navigate = useNavigate();

  return (
    <div className="shadow-md p-3 mx-auto mb-16 rounded-md border border-gray-300">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-64 md:text-xl">Post</TableHead>
            <TableHead className="w-64 text-center md:text-xl">
              Date & Time
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
            <TableRow className="mt-2">
              <TableCell className="md:text-xl">Post Name</TableCell>
              <TableCell className="text-center md:text-xl">
                {convertDateToIST(new Date().toUTCString())}
              </TableCell>
              <TableCell className="text-end">
                  <Button
                    onClick={() => {
                        navigate("/mockexamguidelinepage");
                    }}
                  >
                    Start Exam
                  </Button>
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <div></div>
    </div>
  );
};
