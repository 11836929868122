"use client";
import { MobileIcon } from "@radix-ui/react-icons";
import { AlertTriangle } from "lucide-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import QRCode from "react-qr-code";
import { toast, Toaster } from "sonner";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import { Label } from "src/components/ui/label";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { ScrollArea } from "src/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { Textarea } from "src/components/ui/textarea";
import {
  useLongAnswerStore,
  useMcqAnswerStore,
} from "src/components/zustand/AnswerStore";

import { Modal } from "react-overlays";
import Backdrop from "src/components/ui/Backdrop";
import SubmitExamDialog from "src/components/ui/submit-exam-dialog";
import { TabContent, TabList, Tabs, TabTrigger } from "src/components/ui/Tabs";

import { env } from "src/lib/env";

import { useNavigate } from "react-router-dom";
import { longQuestions, optionalQuestions } from "./questions";

export default function ExamPage() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(true);
  const [showShortAnswerReviewModal, setShowShortAnswerReviewModal] =
    useState(false);
  const [showLongAnswerReviewModal, setShowLongAnswerReviewModal] =
    useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const handle = useFullScreenHandle();

  const [optionalAnswer, setOptionalAnswer] = useState<string | "">("");

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const { mcqanswers, saveMcqAnswer, clearMcqStorage } =
    useMcqAnswerStore();
  const { longanswers, savelongAnswer, clearDescStorage } =
    useLongAnswerStore();

  const [descriptiveAnswer, setDescriptiveAnswer] = useState<string | "">("");
  const [descanswers, setDescanswers] = useState(longanswers);

  const [isActive, setIsActive] = useState(true);
  const [warningCount, setWarningCount] = useState(0);
  const maxWarnings = 3;

  const [answerUrl, setAnswerUrl] = useState<string | "">("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("mcqTab");


  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newDescAnswers = [...descanswers];
    newDescAnswers[currentQuestionIndex] = {
      ...newDescAnswers[currentQuestionIndex],
      descriptiveAnswer: e.target.value,
    };
    setDescanswers(newDescAnswers);
    setDescriptiveAnswer(e.target.value);
  };



  useEffect(() => {
    if (isFirstVisit) {
      clearMcqStorage();
      clearDescStorage();
      setDescanswers(longanswers);
    }
  }, [isFirstVisit]);


  // handle refresh of the page.
  const handleRefresh = useRef((event: { returnValue: string }) => {
    const confirmationMessage = "Are you sure you want to leave?";
    const userConfirmed = window.confirm(confirmationMessage);

    if (userConfirmed) {
      window.removeEventListener("beforeunload", handleRefresh);

      clearMcqStorage();
      clearDescStorage();

      navigate(
        `/mockexamfinish`,
      );
    }

    event.returnValue = confirmationMessage; // Standard for most browsers
    return confirmationMessage; // For some older browsers
  }).current;

  useEffect(() => {
    const handlePageChange = async () => {
      if (document.hidden) {
        // User switched tabs or left the page
        if (warningCount < maxWarnings) {
          toast.warning(
            `Warning: Do not switch tabs or leave the page. You have ${
              maxWarnings - warningCount
            } warnings remaining.`,
          );
          setWarningCount((prevCount) => prevCount + 1);
        } else {
          // Auto-submit the exam after reaching the maximum warnings
          window.removeEventListener("beforeunload", handleRefresh);

          clearMcqStorage();
          clearDescStorage();

          navigate(
            `/mockexamfinish`,
          );
          setIsActive(false);
        }
      }
    };

    document.addEventListener("visibilitychange", handlePageChange);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      document.removeEventListener("visibilitychange", handlePageChange);
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, [isActive, warningCount]);


  const clearLocalStateAnswers = () => {
    setOptionalAnswer("");
    setDescriptiveAnswer("");
    setAnswerUrl("");
  };

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  const handleSaveAndNextmcq = async () => {
    if (currentQuestionIndex > optionalQuestions.length - 1) {
      return;
    }

    if (!!optionalAnswer) {

        saveMcqAnswer(currentQuestionIndex, {
          optionalAnswer:
            optionalAnswer || mcqanswers[currentQuestionIndex]?.optionalAnswer,
        });
        toast.success("Answer saved successfully");

        if (currentQuestionIndex !== optionalQuestions.length - 1) {
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        }
    } else {
      if (currentQuestionIndex !== optionalQuestions.length - 1) {
        toast.warning("Select an Answer to 'Save and Next' or 'Skip'");
      }
    }

    clearLocalStateAnswers();
  };

  const handleSaveAndNextdesc = async () => {
    if (currentQuestionIndex > longQuestions.length - 1) {
      return;
    }

        savelongAnswer(currentQuestionIndex, {
          descriptiveAnswer:
            descanswers[currentQuestionIndex]?.descriptiveAnswer,
          answerUrl: answerUrl,
        });

            const uploadedAnswerurl = "answerurl";

            setAnswerUrl(uploadedAnswerurl);

            savelongAnswer(currentQuestionIndex, {
              descriptiveAnswer:
                descanswers[currentQuestionIndex]?.descriptiveAnswer,
              answerUrl: uploadedAnswerurl,
            });


        toast.success("Answer saved successfully");

        if (currentQuestionIndex !== longQuestions.length - 1) {
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        }

    clearLocalStateAnswers();
  };

  const handleSkip = () => {
    if (currentQuestionIndex >= optionalQuestions.length - 1) {
      return;
    }
    clearLocalStateAnswers();

    toast.success("Skipped");

    if (currentQuestionIndex !== optionalQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex === 0) {
      return;
    }
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleExamSubmit = () => {
    handleSaveAndNextdesc();

    window.removeEventListener("beforeunload", handleRefresh);

    clearMcqStorage();
    clearDescStorage();

    navigate(
      `/mockexamfinish`,
    );
  };

  const enterFullScreen = () => {
    handle.enter();
    setShowWarningModal(false);
  };

  useEffect(() => {
    setIsFullScreen(handle.active);
    openWarningModal();
  }, [handle.active]);

  const openWarningModal = () => {
    if (!isFullScreen && !isFirstVisit) {
      setShowWarningModal(true);
    }
  };
  
  // handles the automatic section change
  const sectionChange = () => {
    setShowShortAnswerReviewModal(false);
    setShowWarningModal(true);
    handleSaveAndNextmcq();
    setCurrentQuestionIndex(0);
    setActiveTab("descTab");
  };

  // handles first section timer end.
  const handleMcqSectionTimerEnd = () => {
    sectionChange();
  };

  // callback function to be called when the timer ends in the second section.
  const handleDescTimerEnd = () => {
    handleExamSubmit();
  };

  async function handleNavigation(index: number): Promise<void> {
    clearLocalStateAnswers();
    setCurrentQuestionIndex(index);
  }

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [currentQuestionIndex]);

  return (
    <div>
      <FullScreen
        className="container mx-auto h-screen bg-white px-4 py-10"
        handle={handle}
      >
        {/* <ShadCNToaster /> */}
        <Toaster position="top-center" richColors className="z-[9999999]" />
        <Tabs
          defaultValue="mcqTab"
          value={activeTab}
          onValueChange={setActiveTab}
        >
          <TabList
            className="border-mauve6 flex justify-end border-b"
            loop={false}
          >
            <TabTrigger
              className="text-mauve11 hover:text-violet11 flex h-[40px] w-[111px] cursor-pointer select-none items-center justify-center bg-white text-lg leading-none text-black outline-none first:rounded-tl-md last:rounded-tr-md data-[state=active]:bg-slate-300 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:shadow-current data-[state=active]:focus:relative data-[state=active]:focus:shadow-[0_0_0_2px] data-[state=active]:focus:shadow-primary"
              value="mcqTab"
              disabled={activeTab === "descTab"}
            >
              Section 1
            </TabTrigger>
            <TabTrigger
              className="text-mauve11 hover:text-violet11 flex h-[40px] w-[111px] cursor-default select-none items-center justify-center bg-white text-lg leading-none text-black outline-none first:rounded-tl-md last:rounded-tr-md data-[state=active]:bg-slate-300 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:shadow-current data-[state=active]:focus:relative data-[state=active]:focus:shadow-[0_0_0_2px] data-[state=active]:focus:shadow-primary"
              value="descTab"
              disabled={activeTab === "mcqTab"}
            >
              Section 2
            </TabTrigger>
          </TabList>
          <TabContent value="mcqTab">
            {activeTab === "mcqTab" && (
              <div className="grid grid-cols-4 gap-10">
                <div className="col-span-3">
                  <div className="mb-6">
                    <div className="flex justify-start">
                      <div className="flex rounded-md bg-gray-200 px-4 py-1">
                        <McqCountdownTimer
                          onTimerEnd={handleMcqSectionTimerEnd}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-10">
                    <h1 className="text-2xl font-semibold">
                      {loading
                        ? "Loading..."
                        : `${currentQuestionIndex + 1}. ${
                            optionalQuestions[currentQuestionIndex]?.question
                          }`}
                    </h1>
                  </div>
                  {loading ? (
                    <div>Loading...</div>
                  ) : (
                    optionalQuestions[currentQuestionIndex]?.type ===
                      "optional" && (
                      <RadioGroup className="grid h-4/5 grid-cols-1 grid-rows-4 gap-4">
                        {Array.from({ length: 4 }, (_, index) => index + 1).map(
                          (optionNumber) => {
                            const optionKey = `option${optionNumber}`;
                            const optionId = `option${optionNumber}`;
                            const optionValue = (
                              optionalQuestions[currentQuestionIndex] as any
                            )[optionKey];

                            return (
                              <div
                                key={optionKey}
                                className={`flex items-center justify-start rounded-md bg-gray-200 hover:cursor-pointer hover:bg-gray-300 ${
                                  optionalAnswer === optionValue
                                    ? "bg-gray-400"
                                    : ""
                                }`}
                                onClick={() => setOptionalAnswer(optionValue)}
                              >
                                {/* if answer is available in the question object then check it.*/}
                                <RadioGroupItem
                                  value={optionValue}
                                  id={optionId}
                                  checked={
                                    optionalAnswer === optionValue ||
                                    mcqanswers[currentQuestionIndex]
                                      ?.optionalAnswer === optionValue
                                  }
                                  className="ml-5"
                                  onChange={() =>
                                    setOptionalAnswer(optionValue)
                                  }
                                />
                                <Label className="ml-2" htmlFor={optionId}>
                                  {optionValue}
                                </Label>
                              </div>
                            );
                          },
                        )}
                      </RadioGroup>
                    )
                  )}
                  {currentQuestionIndex !== 0 && (
                    <Button
                      className="fixed bottom-5 left-5 w-64 p-5"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                  )}
                </div>
                <div className="col-span-1">
                  <ScrollArea className="mt-16 h-[60vh] grow">
                    <ul className="space-y-2 overflow-y-auto">
                      {optionalQuestions.map((_, index) => (
                        <li
                          key={index}
                          className={`${currentQuestionIndex === index ? "font-semibold text-primary" : ""} flex flex-row items-center rounded-md px-1 hover:bg-secondary`}
                        >
                          <Checkbox
                            id={`Question ${index + 1}`}
                            checked={!!mcqanswers[index]?.optionalAnswer}
                          />
                          <span className="mx-1">{`Q.${index + 1}`}</span>
                          <div
                            className="ml-1 flex min-h-10 cursor-pointer items-center justify-start text-nowrap p-1 pr-2"
                            onClick={() => handleNavigation(index)}
                          >
                            {optionalQuestions[index].question.length >
                            35
                              ? `${optionalQuestions[index].question.slice(0, 35)}...`
                              : `${optionalQuestions[index].question}`}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </ScrollArea>

                  <div className="fixed bottom-5 right-5 space-x-3 space-y-3">
                    {currentQuestionIndex !== optionalQuestions.length - 1 && (
                      <Button
                        className={`w-32 p-5 ${
                          (
                            !!descriptiveAnswer ||
                            !!optionalAnswer) &&
                          "hidden"
                        }`}
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}
                    {currentQuestionIndex !== optionalQuestions.length - 1 ? (
                      <Button
                        className="w-56 p-5"
                        onClick={handleSaveAndNextmcq}
                      >
                        Save and Next
                      </Button>
                    ) : (
                      <Button
                        className="w-64 p-5"
                        onClick={() => {
                          handle.exit();
                          setShowWarningModal(false);
                          handleSaveAndNextmcq();
                          setShowShortAnswerReviewModal(true);
                        }}
                      >
                        Save and Review
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </TabContent>
          <TabContent value="descTab">
            {activeTab === "descTab" && (
              <div className="grid grid-cols-4 gap-10">
                <div className="col-span-3">
                  <div className="mb-6">
                    <div className="flex justify-start">
                      <div className="rounded-md bg-gray-200 px-4 py-2">
                        <DescCountdownTimer
                          onTimerEnd={handleDescTimerEnd}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-10">
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <div>
                        <h1 className="text-2xl font-semibold">
                          {`${currentQuestionIndex + 1}. ${
                            longQuestions[currentQuestionIndex]?.question
                          }`}
                        </h1>
                        <h2 className="text-lg font-semibold text-red-500">
                          {longQuestions[currentQuestionIndex]?.type === "short"
                            ? "(Short Answer in maximum one sentence)"
                            : "(Descriptive Answer in maximum two paragraph)"}
                        </h2>
                      </div>
                    )}
                  </div>
                  {loading ? (
                    <div>Loading...</div>
                  ) : longQuestions[currentQuestionIndex]?.type ===
                    "short" ? (
                    <div>
                      <Textarea
                        className="mb-4 h-[35vh] w-full resize-none border p-4"
                        placeholder="Type your answer here..."
                        ref={textAreaRef}
                        maxLength={300}
                        value={
                          descanswers[currentQuestionIndex]
                            ?.descriptiveAnswer || ""
                        }
                        onChange={handleInputChange}
                        onPaste={(e) => {
                          e.preventDefault();
                          toast.warning("Pasting is not allowed!");
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <Textarea
                        className="mb-4 h-[40vh] w-full resize-none border p-4"
                        placeholder="Type your answer here..."
                        ref={textAreaRef}
                        value={
                          descanswers[currentQuestionIndex]
                            ?.descriptiveAnswer || ""
                        }
                        onChange={handleInputChange}
                        onPaste={(e) => {
                          e.preventDefault();
                          toast.warning("Pasting is not allowed!");
                        }}
                      />
                      <div className="sticky bottom-3">
                        <div className="flex flex-col items-center justify-center">
                          <div className="flex items-center space-x-2">
                            <p className="text-lg">OR</p>
                            <MobileIcon className="h-6 w-6 text-green-500" />
                            <p className="text-lg">
                              Upload handwritten answer from mobile by scanning
                              the QR and after successfully uploading the answer
                              in mobile click save and next here.
                            </p>
                          </div>
                          <QRCode
                            value={`${env.REACT_APP_FRONTEND_URL}/mockuploadanswer`}
                            className="h-36 w-36"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {currentQuestionIndex !== 0 && (
                    <Button
                      className="fixed bottom-5 left-5 w-64 p-5"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                  )}
                </div>

                <div className="col-span-1">
                  <ScrollArea className="mt-16 h-[60vh]">
                    <ul className="space-y-2 overflow-y-auto">
                      {longQuestions.map((_, index) => (
                        <li
                          key={index}
                          className={`${currentQuestionIndex === index ? "font-semibold text-primary" : ""} flex flex-row items-center rounded-md hover:bg-secondary`}
                        >
                          <Checkbox
                            id={`Question ${index + 1}`}
                            checked={
                              !!longanswers[index]?.answerUrl ||
                              !!longanswers[index]?.descriptiveAnswer
                            }
                          />
                          <span className="mx-1">{`Q.${index + 1}`}</span>
                          <div
                            className="flex min-h-10 cursor-pointer items-center justify-start text-nowrap px-1 py-2 pr-2"
                            onClick={() => handleNavigation(index)}
                          >
                            {longQuestions[index].question.length > 35
                              ? `${longQuestions[index].question.slice(0, 35)}...`
                              : `${longQuestions[index].question}`}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </ScrollArea>

                  <div className="fixed bottom-5 right-5 space-x-3 space-y-3">
                    {currentQuestionIndex !== longQuestions.length - 1 && (
                      <Button
                        className={`w-32 p-5 ${
                          (
                            !!descriptiveAnswer ||
                            !!optionalAnswer) &&
                          "hidden"
                        }`}
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}
                    {currentQuestionIndex !== longQuestions.length - 1 ? (
                      <Button
                        className="w-56 p-5"
                        onClick={handleSaveAndNextdesc}
                      >
                        Save and Next
                      </Button>
                    ) : (
                      <Button
                        className="w-64 p-5"
                        onClick={() => {
                          handle.exit();
                          setShowWarningModal(false);
                          handleSaveAndNextdesc();
                          setShowLongAnswerReviewModal(true);
                        }}
                      >
                        Save and Review
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </TabContent>
        </Tabs>
      </FullScreen>
      <Modal
        show={showShortAnswerReviewModal}
        onHide={() => setShowShortAnswerReviewModal(false)}
        onEscapeKeyDown={(e: KeyboardEvent) => e.preventDefault()}
        backdrop={true}
        renderBackdrop={(props) => <Backdrop {...props} />}
        aria-labelledby="start exam warning!"
        style={{
          borderRadius: "8px",
          border: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "#e0daed",
          width: "60%",
          padding: "20px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1040,
        }}
      >
        <div>
          <div>
            <h1 className="text-center font-semibold text-xl">Your first section has been completed successfully. The next section will begin automatically once the timer ends. In the meantime, you may review your answers.</h1>
          </div>
          <ScrollArea className="h-[75vh] ">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-48">Questions</TableHead>
                  <TableHead className="pr-4 text-right">Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {optionalQuestions.map((question, index) => (
                  <TableRow key={index}>
                    <TableCell className="w-[80%] truncate text-wrap font-medium">
                      {`${index + 1}.${question.question}`}
                    </TableCell>
                    <TableCell className="pr-4 text-right">
                      {!!mcqanswers[index]?.optionalAnswer
                        ? "Attempted"
                        : "Not Attempted"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
          <div className="flex w-full justify-end gap-2">
            <Button
              onClick={() => {
                handle.enter();
                setShowShortAnswerReviewModal(false);
              }}
            >
              Go back
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        show={showLongAnswerReviewModal}
        onHide={() => setShowLongAnswerReviewModal(false)}
        onEscapeKeyDown={(e: KeyboardEvent) => e.preventDefault()}
        backdrop={true}
        renderBackdrop={(props) => <Backdrop {...props} />}
        aria-labelledby="start exam warning!"
        style={{
          borderRadius: "8px",
          border: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "#e0daed",
          width: "60%",
          padding: "20px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1040,
        }}
      >
        <div>
          <ScrollArea className="h-[75vh] ">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-48">Questions</TableHead>
                  <TableHead className="pr-4 text-right">Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {longQuestions.map((question, index) => (
                  <TableRow key={index}>
                    <TableCell className="w-[80%] truncate text-wrap font-medium">
                      {`${index + 1}.${question.question}`}
                    </TableCell>
                    <TableCell className="pr-4 text-right">
                      {!!longanswers[index]?.descriptiveAnswer ||
                      !!longanswers[index]?.answerUrl
                        ? "Attempted"
                        : "Not Attempted"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
          <div className="flex w-full justify-end gap-2">
            <Button
              onClick={() => {
                handle.enter();
                setShowLongAnswerReviewModal(false);
              }}
            >
              Go back
            </Button>
            <SubmitExamDialog onClick={handleExamSubmit}>
              <Button className="font-medium text-white">Submit</Button>
            </SubmitExamDialog>
          </div>
        </div>
      </Modal>
      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        onEscapeKeyDown={(e: KeyboardEvent) => e.preventDefault()}
        backdrop={true}
        renderBackdrop={(props) => <Backdrop {...props} />}
        aria-labelledby="start exam warning!"
        style={{
          border: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "#e0daed",
          width: "60%",
          padding: "20px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1040,
        }}
      >
        <div className="flex flex-col gap-5">
          <div className="mb-4 text-center text-2xl font-bold text-red-600">
            {isFirstVisit ? "Start the exam" : "Warning"}
          </div>
          {isFirstVisit ? (
            <>
              <div className="mb-4 flex flex-col items-center justify-center text-center">
                <p className="text-xl font-semibold text-red-600">
                  Please don't refresh or change internet browser tabs during
                  the Exam.
                </p>
                <p>
                  Any attempt to leave the screen will result in a submission of
                  the exam.
                </p>
              </div>
              <Button
                onClick={() => {
                  setShowWarningModal(false);
                  setIsFirstVisit(false);
                  enterFullScreen();
                  setLoading(false);
                }}
                disabled= {optionalQuestions.length === 0 }
              >
                Okay
              </Button>
            </>
          ) : (
            <div className="flex flex-col items-center gap-8">
              <div className="flex flex-col items-center justify-center text-red-600">
                <AlertTriangle size="32" />
                <p>You are not in fullscreen mode.</p>
                <p>
                  Any attempt to leave the screen will result in a submission of
                  the exam.
                </p>
              </div>
              <Button onClick={enterFullScreen}>Go Fullscreen</Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

interface CountdownTimerProps {
  onTimerEnd: () => void;
}

const McqCountdownTimer: React.FC<CountdownTimerProps> = ({
  onTimerEnd,
}) => {
  const [remainingTime, setRemainingTime] = useState<string>("00:00");

  const serverTimeString = new Date().toUTCString();
  const serverTime = new Date(serverTimeString).getTime();
  const localTime = Date.now();
  const offset = serverTime - localTime;
  const targetTimemili = new Date().getTime() + 5 * 60 * 1000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const adjustedTime = now + offset;

      const timeRemaining = Math.max(0, targetTimemili - adjustedTime);

      if (timeRemaining <= 0) {
        clearInterval(intervalId);
        onTimerEnd();
      } else {
        const minutes = Math.floor(timeRemaining / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
        setRemainingTime(
          `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
        );
      }
    }, 1000);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  if(remainingTime === "02:00") {
    toast.warning("2 Minutes Remaining");
  }

  return (
    <>
      <span className="font-mono text-xl">{remainingTime}</span>
    </>
  );
};

const DescCountdownTimer: React.FC<CountdownTimerProps> = ({
  onTimerEnd,
}) => {
  const [remainingTime, setRemainingTime] = useState<string>("00:00");

  const serverTimeString = new Date().toUTCString();
  const serverTime = new Date(serverTimeString).getTime();
  const localTime = Date.now();
  const offset = serverTime - localTime;
  const targetTimemili = new Date().getTime() + 10 * 60 * 1000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const adjustedTime = now + offset;

      const timeRemaining = Math.max(0, targetTimemili - adjustedTime);

      if (timeRemaining <= 0) {
        clearInterval(intervalId);
        onTimerEnd();
      } else {
        const minutes = Math.floor(timeRemaining / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
        setRemainingTime(
          `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
        );
      }
    }, 1000);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  if(remainingTime === "02:00") {
    toast.warning("2 Minutes Remaining");
  }

  return (
    <>
      <span className="font-mono text-xl">{remainingTime}</span>
    </>
  );
};
